.container-carousel {
    color: black;
    position: relative;
    z-index: 10;
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
        width: auto;
    }
    .alice-carousel__prev-btn {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
    }
    .alice-carousel__next-btn {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
    .alice-carousel__prev-btn-item,
    .alice-carousel__next-btn-item {
        color: $black;
        font-size: 35px;
    }
    .alice-carousel__dots-item {
        margin: 0 5px !important;
    }
    .alice-carousel__stage-item {
        min-height: 100px;
        line-height: 100px;
        vertical-align: middle;
    }
}

/* ----- Media query ----- */
@include md {
    .container-carousel {
        .alice-carousel__stage-item{
            margin-bottom: 25px;
            margin-top: 25px;

        }
    }
}
