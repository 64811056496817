form {
    // box-shadow: 0 0 10px 1px $light-grey;
    padding: 25px;
    border-radius: 6px;
}
.btn-default {
    padding: 12px 30px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    height: auto;
    border: none;
    display: inline-block;
}

.btn-white {
    background-color: $white;
    color: $green-dark;

    &:hover,
    &:focus {
        background-color: $light-grey;
        color: $green-dark;
    }
}

.btn-orange {
    background-color: $orange;
    color: $white;

    &:hover,
    &:focus {
        background-color: $dark-orange;
        color: $white;
    }
}

.btn-green {
    background-color: $green-light;
    color: $white;

    &:hover,
    &:focus {
        background-color:  $green-ted;
        color: $white;
    }
}
.btn-blue {
    background-color: $green-blue;
    color: $white;

    &:hover,
    &:focus {
        background-color:  $blue-dark;
        color: $white;
    }
}
.double-input {
    display: flex;
    justify-content: space-between;
    div {
        width: 47%;
    }
}
label {
    text-align: left;
    margin-top: 25px;
    display: block;
}

@include xl {
    form {
        padding: 50px 75px;
    }
    .btn-default {
        font-size: 18px;
        padding: 12px 30px;
    }
}
