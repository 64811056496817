#projectPage {
    #project-not-available {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        z-index: 5;
        .not-available-container {
            background-color: $green-blue;
            box-shadow: 2px 2px 10px 2px #7eacb9b2;
            padding: 50px;
            border-radius: 6px;
            font-weight: bold;
            color: white;
            font-size: 22px;
            max-width: 90vw;
            a {
                color: $green-blue;
                svg {
                    width: 22px;
                    height: 22px;
                    margin-left: 10px;
                    fill: $green-blue;
                }
            }
        }
    }
    .blur-component {
        filter: blur(20px);
        -webkit-user-select: none;
         -moz-user-select: none;
         -ms-user-select: none;
         user-select: none;
    }
    .project-context {
        padding-top: 70px;
        z-index: 2;

        .image-container {
            margin-right: 25px;
            img {
                display: block;
                margin: auto;
                background-color: $orange;
                width: 200px;
                height: 200px;
                border-radius: 50%;
                padding: 50px;
            }
        }
        .context-container {
            text-align: left;
            font-variant-numeric: tabular-nums;
            h2 {
                margin-top: 35px;
                margin-bottom: 25px;
                display: block;
                text-align: center;
            }
            .description {
                padding: 0 35px;
                font-size: 20px;
            }
            h3 {
                display: block;
                text-align: center;
                margin-top: 25px;
                margin-bottom: 25px;
            }
            .painPoint-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 40px;
                .painPoint {
                    background-color: $green-blue;
                    margin-right: 15px;
                    margin-top: 15px;
                    word-wrap: wrap;
                    color: white;
                    font-weight: bold;
                    border-radius: 10px;
                    width: 120px;
                    min-height: 80px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    p {
                        margin: auto;
                        line-height: 1;
                        word-break: break;
                        padding: 10px;
                    }
                }
            }
            li {
                list-style-type: disc;
            }
        }
    }
    .roiUseCase button {
        max-width: 80vw;
    }
}
@include md {
    #projectPage {
        .project-context {
            .context-container {
                ul {
                    li {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
@include lg {
    #projectPage {
        #project-not-available {
            .not-available-container {
                padding: 75px 100px;
                font-size: 36px;
                a {
                    svg {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
        }

        .project-context {
            .context-container {
                width: calc(100% - 200px);
                h2 {
                    text-align: left;
                }
                ul {
                    padding-left: 50px;
                }
            }
        }
    }
}

@include xl {
    #projectPage {
        .project-context {
            padding-left: 5vw;
            padding-right: 5vw;
            .image-container {
                img {
                    width: 300px;
                    height: 300px;
                }
            }
            .context-container {
                width: calc(100% - 300px);

                h2 {
                    margin-top: 0;
                }

                h3 {
                    margin-bottom: 0;
                }
                .painPoint-container {
                    .painPoint {
                        min-height: 90px;
                    }
                }
                ul {
                    li {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}
@include xxl {
    #projectPage {
        .project-context {
            padding-left: 15vw;
            padding-right: 15vw;
            .image-container {
                img {
                    width: 350px;
                    height: 350px;
                }
            }
            .context-container {
                width: calc(100% - 350px);

                h2 {
                    margin-bottom: 50px;
                }
                .description {
                    font-size: 24px;
                }
            }
        }
    }
}
