#projets {
    background-color: $green-white;

    h2 {
        @include title-bubble-right($green-ted);
    }
}

.project {
    display: block;
    width: 325px;
    height: 450px;
    margin: auto;
    background-color: white;
    border-radius: 16px;
    padding: 25px;
    box-shadow: 0 0 10px 1px $light-grey;
    border: 1px solid rgb(240, 240, 240);
    img {
        border-radius: 12px;
        width: 100%;
    }
    h3 {
        margin-top: 25px;
        color: $black;
        min-height: 60px;
    }
    i {
        color: $blue-dark;
        text-decoration: underline;
        font-weight: bold;
    }
}

/* ----- Media query ----- */
@include sm {
    .project {
        width: 400px;
        padding: 35px;
        height: 500px;

        h3 {
            margin-top: 25px;
            color: $black;
        }
    }
}

@include md {
    .project {
        width: 325px;
        padding: 25px;
        height: 460px;

        h3 {
            font-size: 18px;
        }
    }
}

@include xxl {
    .project {
        width: 375px;
        padding: 35px;

        img {
            width: 90%;
        }
        h3 {
            font-size: 18px;
        }
    }
}
