 /* ----- REVEAL TEXT ----- */
 .reveal-text {
    position: relative;
    animation: clip-text 2s cubic-bezier(0, 0, 0.2, 1) 1;
    height: auto;
    z-index: 2;
    &::after {
        content: "";
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $orange;
        transform: scaleX(0);
        transform-origin: 0 50%;
        pointer-events: none;
        animation: text-revealer 2s cubic-bezier(0, 0, 0.2, 1) 1;
    } 
}


.img-container {
    margin: 0;
    position: absolute;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
    z-index: 1;
    .img-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background-color: $green-white;
        z-index: 2;
    }

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

/* ----- Media xs by default ----- */
#scroll-arrow {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: upAndDown 1.5s ease infinite;
    cursor: pointer;
    img {
        width: 25px;
        height: auto;
    }
}

#scroll-arrow-top {
    position: fixed;
    z-index: 100;
    bottom: 10px;
    left: calc(50% - 7.5px);
    transform: rotate(180deg);
    animation: upAndDown 1.5s ease infinite;
    cursor: pointer;
    img {
        width: 25px;
        height: auto;
    }
}


/* ----- Cursor background ----- */
@keyframes upAndDown {
    0%,
    100% {
        bottom: 10px;
    }
    50% {
        bottom: 0;
    }
}

/* ----- TEXT REVEAL ANIMATION ----- */
@keyframes clip-text {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes text-revealer {
    0%,
    50% {
        transform-origin: 0 50%;
    }

    60%,
    100% {
        transform-origin: 100% 50%;
    }

    60% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}


