#partnersPage,
#coursesPage,
#oneCoursePage {
    h1 {
        font-weight: bold;
    }
    .presentation {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        .text-presentation {
            margin: auto 0;
        }
        h1 {
            font-weight: bold;
            margin: auto;
            text-align: left;
            width: 80%;
        }
        .subtitle {
            width: 80%;
            margin-bottom: 15px;
        }
        img {
            width: 80vw;
            height: auto;
            margin: auto;
            margin-bottom: 25px;
        }
    }
    .subtitle {
        text-align: left;
    }
    .title-container {
        display: flex;
        text-align: left;
        padding-left: 5vw;
        h2 {
            margin: auto;
            margin-left: 0;
            font-size: 22px;
        }
        img {
            width: 100px;
            height: 100px;
        }
    }

    .partners-container {
        flex-wrap: wrap;
        align-items: unset !important;
        justify-content: center;
        margin: 25px 0 !important;
    }
    .partner {
        width: 325px;
        height: auto;
        margin: 25px;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 16px;
        padding: 25px 15px;
        box-shadow: 0 0 10px 1px $light-grey;
        border: 1px solid $green-blue;
        // border: 1px solid rgb(240, 240, 240);
        .image-container {
            height: 120px;
            display: flex;
            align-items: center;
            img {
                width: auto;
                margin: auto;
                z-index: 2;
                width: auto;
                max-width: 100%;
                vertical-align: middle;
                &[alt] {
                    font-size: 30px;
                    font-weight: bold;
                }
            }
        }

        h3 {
            text-align: center;
            height: auto;
            // min-height: 60px;
            margin: 25px auto;
        }

        p {
            height: auto;
            // min-height: 120px;
            text-align: center;
        }
        .btn-green {
            margin-top: 15px;
        }
        .xs-logo {
            height: 30px;
            max-height: 30px;
        }
        .md-logo {
            max-height: 50px;
            height: 50px;
        }
        .xl-logo {
            height: 90px;
            max-height: 90px;
        }
    }
}

/* ----- Media query ----- */
@include sm {
    #partnersPage,
    #coursesPage,
    #oneCoursePage {
        .presentation {
            img {
                width: 400px;
            }
        }

        .title-container {
            h2 {
                font-size: 26px;
            }
        }
        .partner {
            min-height: 450px;
            padding: 25px;
            .btn-green {
                margin-top: 5px;
            }
        }
        .xs-logo {
            height: 40px;
            max-height: 40px;
        }
        .md-logo {
            max-height: 60px;
            height: 60px;
        }
        .xl-logo {
            height: 120px;
            max-height: 120px;
        }
    }
}

@include md {
    #partnersPage,
    #coursesPage,
    #oneCoursePage {
        .title-container {
            h2 {
                font-size: 26px;
            }
        }
        .partner {
            h3 {
                min-height: 60px;
            }

            p {
                min-height: 120px;
            }
        }

        .xs-logo {
            height: 50px;
            max-height: 50px;
        }
        .md-logo {
            max-height: 70px;
            height: 70px;
        }
        .xl-logo {
            height: 140px;
            max-height: 140px;
        }
    }
}

@include lg {
    #coursesPage {
        .presentation {
            flex-direction: row;
            .text-presentation {
                margin: auto 0 auto 10vw;
            }

            img {
                width: 300px;
                margin-bottom: 0;
                margin-right: 10vw;
            }
        }
    }
}

@include xl {
    #partnersPage,
    #coursesPage,
    #oneCoursePage {
        .presentation {
            img {
                width: 40vw;
                margin-bottom: 0;
                margin-right: 10vw;
            }
        }

        .title-container {
            padding-left: 15vw;

            h2 {
                font-size: 35px;
            }
        }
        .xs-logo {
            height: 40px;
            max-height: 40px;
        }
        .md-logo {
            max-height: 80px;
            height: 80px;
        }
        .xl-logo {
            height: 140px;
            max-height: 140px;
        }
    }
}
