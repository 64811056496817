#videoBSmart {
    background-color: $green-white;
    box-shadow: 0 0 10px 1px $light-grey;
    position: relative;
    padding-top: 0;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    h2 {
        color: $green-dark;
        margin-top: 25px;
        margin-bottom: 25px;
    }
    p {
        font-size: 20px;
    }
    iframe {
        margin: 100px auto 0 auto;
    }
    h2,
    p,
    iframe {
        position: relative;
        z-index: 5;
    }
}
