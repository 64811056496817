#blogPage,
#blogPostPage {
    // ------------------
    // Banner presentation
    // ------------------
    .text-presentation {
        width: 90vw;
        margin: auto;

        #title-banner {
            border-radius: 25px;
            background: rgb(255, 163, 132);
            background: linear-gradient(180deg, rgba(255, 163, 132, 0.9) 0%, rgba(246, 189, 96, 0.9) 100%);
            width: 100%;
            color: $white;
            padding: 25px 50px;
            h1 {
                font-size: 70px;
                font-weight: bold;
                margin-bottom: 30px;
            }
            p {
                font-size: 20px;
            }
        }
        .subtitle {
            width: 100%;
            text-align: left;
            font-weight: bold;
            font-size: 22px;
            margin-top: 50px;
            margin-bottom: 15px;
        }
        .hr {
            display: block;
            height: 4px;
            background-color: $orange;
            border: 0;
            width: 160px;
        }
    }
    // ------------------
    // Posts list
    // ------------------
    .posts-container {
        display: grid;
        grid-template-columns: repeat(1, auto);
        justify-content: center;
    }
    .article-blog {
        margin-bottom: 50px;
        border: 4px solid #ffa38481;
        border-radius: 15px;
        width: 90vw;
        height: auto;
        padding: 15px;
        text-align: left;
        max-width: 400px;
        cursor: pointer;

        img {
            width: 100%;
            height: auto;
            max-height: 200px;
            border-radius: 15px;
        }
        .post-content {
            color: $black;

            .post-title {
                font-size: 20px;
                margin-bottom: 25px;
                margin-top: 15px;
                &:hover {
                    color: rgb(112, 112, 112) !important;
                }
            }
            .post-description {
                font-family: $font-family-text;
                font-size: 16px;
                &:hover {
                    color: rgb(112, 112, 112) !important;
                }
            }
            .post-read-more {
                text-align: right;
                &:hover {
                    color: rgb(112, 112, 112) !important;
                }
            }
        }

        .post-footer {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            p {
                margin-bottom: 0;
            }
        }
    }
}
#blogPostPage {
    #blogPost-container {
        width: 90vw;
        margin: auto;
        // ------------------
        // Post by ID
        // ------------------
        .post-content-rendered {
            .post-header {
                display: flex;
                font-style: italic;
                justify-content: space-between;
            }
            .post-title {
                text-align: left;
                margin-top: 25px;
            }
            .post-content {
                text-align: left;
                img {
                    max-width: 100%;
                    height: auto;
                    max-height: 350px;
                    margin: 25px auto;
                    display: block;
                    border-radius: 4px;
                }
                h2,
                h3 {
                    margin-bottom: 25px;
                    margin-top: 20px;
                }
                h3 {
                    font-size: 24px;
                }
                .link-content {
                    color: rgb(49, 117, 235);
                    font-weight: bold;
                    text-decoration: underline;

                    &:hover {
                        color: blue;
                    }
                }
            }
            .hr {
                display: block;
                height: 2px;
                background-color: $orange;
                border: 0;
                width: 100%;
            }

            .post-tags {
                display: flex;
                flex-wrap: wrap;
                margin-top: 70px;
                p {
                    background-color: $orange;
                    color: white;
                    padding: 15px 25px;
                    margin-right: 15px;
                    border-radius: 6px;
                }
            }

            .post-footer {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 25px 0;

                #social-media {
                    svg {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }
                }
            }
        }
        // ------------------
        // Recent posts list (the last 3)
        // ------------------
        .recent-posts-component {
            margin-top: 50px;

            h3 {
                text-align: left;
                display: block;
                font-size: 24px;
                text-decoration: underline;
                text-decoration-color: $orange;
                text-underline-offset: 8px;
                text-decoration-thickness: 2px;
            }
            #see-posts {
                text-align: left;
                display: block;
                font-weight: 500;
                font-size: 18px;
                &:hover {
                    text-decoration: underline;
                }
            }
            .recent-posts-container {
                display: flex;
                flex-direction: column;

                .recent-post {
                    margin: 15px auto;
                    border: 3px solid $orange;
                    border-radius: 8px;
                    padding: 0 20px;
                    max-width: 350px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: auto;
                        margin: 25px auto;
                        display: block;
                        border-radius: 4px;
                    }

                    .post-content {
                        text-align: left;

                        h4 {
                            margin: 15px 0;
                            font-size: 20px;
                        }
                        .post-title {
                            &:hover {
                                color: rgb(112, 112, 112) !important;
                            }
                        }
                    }

                    .post-footer {
                        margin: 0;
                        display: flex;
                        justify-content: space-between;
                        &:hover {
                            color: rgb(112, 112, 112) !important;
                        }
                        svg {
                            fill: $orange;
                            width: 22px;
                            height: 22px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
/* ----- Media query ----- */
@include sm {
    #blogPage,
    #blogPostPage {
        .text-presentation {
            #title-banner {
                padding: 50px 75px;
                h1 {
                    font-size: 90px;
                }
                p {
                    font-size: 22px;
                }
            }
            .subtitle {
                font-size: 26px;
            }
        }

        .article-blog {
            .post-content {
                .post-title {
                    font-size: 22px;
                }
            }
        }
    }
    #blogPostPage {
        #blogPost-container {
            width: 80vw;
            // ------------------
            // Post by ID
            // ------------------
            .post-content-rendered {
                .post-content {
                    h3 {
                        font-size: 26px;
                    }
                    img {
                        max-width: 450px;
                    }
                }
            }
        }
    }
}

@include md {
    #blogPostPage {
        #blogPost-container {
            // ------------------
            // Recent posts list (the last 3)
            // ------------------
            .recent-posts-component {
                .recent-posts-container {
                    flex-direction: row;
                    flex-wrap: wrap;
                    .recent-post {
                        max-width: 320px;
                        margin: 10px 20px 10px 0;
                    }
                }
            }
        }
    }
}
@include lg {
    #blogPage,
    #blogPostPage {
        .text-presentation {
            #title-banner {
                padding: 75px 150px;
                h1 {
                    font-size: 120px;
                }
                p {
                    font-size: 26px;
                }
            }
        }

        .posts-container {
            grid-template-columns: repeat(2, auto);
        }
        .article-blog {
            margin: 0 50px 50px 50px;
            width: 400px;
            padding: 20px;

            .post-content {
                .post-title {
                    font-size: 24px;

                    min-height: 90px;
                }
                .post-description {
                    min-height: 250px;
                }
            }
        }
    }
    #blogPostPage {
        #blogPost-container {
            width: 80vw;
            // ------------------
            // Post by ID
            // ------------------
            .post-content-rendered {
                sub {
                    font-size: 16px;
                }
                p {
                    font-size: 18px;
                }
                strong {
                    font-size: 20px;
                }
                h3 {
                    strong {
                        font-size: 28px;
                    }
                }
                h4 {
                    strong {
                        font-size: 24px;
                    }
                }

                svg {
                    width: 30px;
                    height: 30px;
                }
            }
            // ------------------
            // Recent posts list (the last 3)
            // ------------------
            .recent-posts-component {
                .recent-posts-container {
                    flex-direction: row;
                    flex-wrap: wrap;
                    .recent-post {
                        margin: 15px 30px 15px 0;
                        border: 3px solid $orange;
                    }
                }
            }
        }
    }
}

@include xl {
    #blogPage,
    #blogPostPage {
        .text-presentation {
            width: 60vw;

            #title-banner {
                h1 {
                    font-size: 140px;
                    margin-bottom: 50px;
                }
            }
            .subtitle {
                font-size: 30px;
            }
        }
        #blogPost-container {
            width: 60vw;
        }
    }
}
