/* ----- Cursor border-radius ----- */
@keyframes blobRadius {
    0%,
    100% {
        border-radius: 43% 77% 80% 40% / 40% 40% 80% 80%;
    }
    20% {
        border-radius: 47% 73% 61% 59% / 47% 75% 45% 73%;
    }
    40% {
        border-radius: 46% 74% 74% 46% / 74% 58% 62% 46%;
    }
    60% {
        border-radius: 47% 73% 61% 59% / 40% 40% 80% 80%;
    }
    80% {
        border-radius: 50% 70% 52% 68% / 51% 61% 59% 69%;
    }
}
/* ----- Cursor background ----- */
@keyframes blobBackground {
    0%,
    100% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
}

/* ----- Cursor with blob colored ----- */
.cursor-dot,
.cursor-dot-outline {
    display: none;
    z-index: 10;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.cursor-dot {
    width: 8px;
    height: 8px;
    background-color: white;
}

.cursor-dot-outline {
    background: linear-gradient(120deg, #ff1744, #e040fb, #2979ff, #00e5ff, #76ff03);
    background-size: 1600% 1600%;
    width: 50px;
    height: 50px;
    mix-blend-mode: difference;
    pointer-events: none;
    transition: 0.15s linear;
    animation: blobRadius 5s ease infinite, blobBackground 15s ease infinite;
}

/* ----- Media query ----- */
@include lg {
    .cursor-dot,
    .cursor-dot-outline {
        display: block;
    }
}
@include sm-landscape {
    .cursor-dot,
    .cursor-dot-outline {
        display: none;
    }
}
