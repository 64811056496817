#portfolioPage {
    .subtitle {
        text-align: left;
    }
    .presentation {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        .text-presentation {
            margin: auto 0;
        }
        h1 {
            font-weight: bold;
            margin: auto;
            text-align: left;
            width: 80%;
        }
        .subtitle {
            width: 80%;
        }
        img {
            width: 80vw;
            height: auto;
            margin: auto;
            margin-bottom: 25px;
        }
    }
    .title-container {
        display: flex;
        text-align: left;
        padding-left: 5vw;
        h2 {
            margin: auto;
            margin-left: 0;
            font-size: 22px;
        }
        img {
            width: 100px;
            height: 100px;
        }
    }

    .partners-container {
        flex-wrap: wrap;
        align-items: unset !important;
        margin: 25px 0 !important;
        .video-component {
            width: 300px;
            height: auto;
            justify-content: center;
            margin: auto;
            iframe,
            img {
                width: 300px;
                height: 168px;
                border-radius: 4px;
                box-shadow: 0 0 10px 1px $light-grey;
            }
            p,
            a {
                display: block;
                text-align: center;
                margin-top: 15px;
                font-size: 18px;
            }
            svg {
                position: absolute;
                top: 60px;
                left: 50%;
                transform: translateX(-50%);
                display: none;
                fill: red;
            }
            a:hover {
                font-weight: 600;
                img {
                    filter: blur(2px);
                }
                svg {
                    display: block;
                }
            }
        }
        .podcast-container {
            display: flex;
            flex-direction: column;
            margin-left: 0;
            width: 300px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .partner {
        width: 325px;
        height: 450px;
        margin: 25px;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 16px;
        padding: 25px;
        box-shadow: 0 0 10px 1px $light-grey;
        border: 1px solid $green-blue;

        .image-container {
            height: 120px;
            display: flex;
            align-items: center;
            img {
                width: auto;
                margin: auto;
                z-index: 2;
                width: auto;
                max-width: 100%;
                vertical-align: middle;
                &[alt] {
                    font-size: 30px;
                    font-weight: bold;
                }
            }
        }
        h3 {
            text-align: center;
            min-height: 60px;
            margin-top: 25px;
        }

        p {
            min-height: 120px;
            text-align: center;
        }
    }
}

/* ----- Media query ----- */
@include sm {
    #portfolioPage {
        .presentation {
            img {
                width: 400px;
            }
        }

        .title-container {
            h2 {
                font-size: 26px;
            }
        }
    }
}

@include md {
    #portfolioPage {
        .title-container {
            h2 {
                font-size: 26px;
            }
        }
    }
}
@include lg {
    #portfolioPage {
        .presentation {
            flex-direction: row;
            .text-presentation {
                margin: auto 0 auto 10vw;
            }

            img {
                width: 300px;
                margin-bottom: 0;
                margin-right: 10vw;
            }
        }

        .title-container {
            h2 {
                font-size: 26px;
            }
        }
    }
}

@include xl {
    #portfolioPage {
        .presentation {
            img {
                width: 40vw;
                margin-bottom: 0;
                margin-right: 10vw;
            }
        }

        .title-container {
            padding-left: 15vw;

            h2 {
                font-size: 35px;
            }
        }
    }
}
