#partners,
#technologies,
#certifications,
#projectTools {
    background-color: $blue-white;
    h2 {
        margin-bottom: 25px;
    }

    img {
        z-index: 2;
        width: auto;
        max-width: 100%;
        vertical-align: middle;
    }

    .xs-logo {
        height: 30px;
        max-height: 30px;
    }
    .md-logo {
        max-height: 50px;
        height: 50px;
    }
    .xl-logo {
        height: 90px;
        max-height: 90px;
    }
}

/* ----- Media query ----- */
@include sm {
    #partners,
    #technologies,
    #certifications,
    #projectTools {
        .xs-logo {
            height: 40px;
            max-height: 40px;
        }
        .md-logo {
            max-height: 60px;
            height: 60px;
        }
        .xl-logo {
            height: 120px;
            max-height: 120px;
        }
    }
}

@include md {
    #partners,
    #technologies,
    #certifications,
    #projectTools {
        .xs-logo {
            height: 50px;
            max-height: 50px;
        }
        .md-logo {
            max-height: 70px;
            height: 70px;
        }
        .xl-logo {
            height: 140px;
            max-height: 140px;
        }
    }
}

@include xl {
    #partners,
    #technologies,
    #certificates {
        .xs-logo {
            height: 40px;
            max-height: 40px;
        }
        .md-logo {
            max-height: 80px;
            height: 80px;
        }
        .xl-logo {
            height: 140px;
            max-height: 140px;
        }
    }
    #projectTools {
        .xs-logo {
            height: 60px;
            max-height: 60px;
        }
        .md-logo {
            max-height: 100px;
            height: 100px;
        }
        .xl-logo {
            height: 160px;
            max-height: 160px;
        }
    }
}
