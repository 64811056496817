#loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(45deg, $green-white 0%, $green-white 50%, $green-light 100%);
    img {
        display: block;
        margin: auto;
        width: 250px;
        height: auto;
        animation: scale 1s ease-in-out;
    }
    .reveal-text {
        &::after {
            background-color: $green-white;
        }
    }
    p {
        font-size: 26px;
        font-weight: bold;
        font-family: $font-family-title;
        text-align: center;
    }
}
@keyframes scale {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

/* ----- Media query ----- */
@include sm {
    #loader {
        img {
            width: 350px;
        }
        p {
            font-size: 40px;
        }
    }
}

@include md {
    #loader {
        img {
            width: 350px;
        }
        p {
            font-size: 50px;
        }
    }
}


.loaderSuspense {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .lds-ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid $green-ted;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $green-ted transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  