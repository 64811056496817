/* ----- Colors Maquette ----- */
$black: #000;
$grey: #6f7581;
$light-grey: #e6e6e6;
$dark-white: #f5f5f5;
$white: #ffffff;

// Green
$dark: #143c3c;
$green-dark: #567568;
$green-ted: #abd4c4;
$green-light: #bee1d4;
$green-white: #f7fffc;
$blue-dark: #598f9d;
$green-blue: #7eacb9;
$blue-white: #f4fcff;

// Other
$yellow: #f6bd60;
$orange: #ffa384;
$dark-orange: #ff8c65;

/* ----- Font/text values ----- */
// Title - League Spartan
$font-family-title: "League Spartan", sans-serif;

// Text - Open Sans
$font-family-text: "Open Sans", sans-serif;

/* ----- Breakpoint values ----- */

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Large tablets and desktops
$screen-xxl-min: 1500px;

// Smartphone
@mixin sm {
    @media (min-width: #{$screen-sm-min}) and (min-height: #{$screen-sm-min}) {
        @content;
    }
}

@mixin sm-landscape {
    @media (max-width: #{$screen-lg-min}) and (max-height: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

// Xxl devices
@mixin xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}

// ----- Variable mixins -----
@mixin title-bubble-left($color) {
    &:before {
        content: "";
        position: absolute;
        width: 90px;
        height: 90px;
        z-index: -1;
        border-radius: 50%;
        background-color: $color;
        left: -50px;
        top: -30px;
    }
    @include sm {
        &:before {
            width: 120px;
            height: 120px;
            left: -70px;
            top: -50px;
        }
    }
}
@mixin title-bubble-right($color) {
    &:before {
        content: "";
        position: absolute;
        width: 90px;
        height: 90px;
        z-index: -1;
        border-radius: 50%;
        background-color: $color;
        right: -50px;
        top: -30px;
    }
    @include sm {
        &:before {
            width: 120px;
            height: 120px;
            left: -70px;
            top: -50px;
        }
    }
}
