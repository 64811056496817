#poles {
    .container-centered {
        flex-direction: column;
    }
    .pole-container {
        width: 350px;
        position: relative;
        // background-color: #d9eefba5;
        display: flex;
        flex-wrap: wrap;
        padding: 25px 15px;
        justify-content: center;
        border-radius: 25px;
        margin: 15px;
        min-height: 370px;
        z-index: 2;

        .bg-rosace {
            position: relative;
            // top: 50%;
            // left: 50%;
            // transform: translate(-48%, -50%);
            z-index: 1;
            width: 350px;
            height: 350px;
        }
        h3 {
            width: 100%;
            text-align: center;
            font-size: 26px;
            background-color: rgba(126, 172, 185, 0.1);
            border-radius: 6px;
            padding: 25px 10px;
        }
        .pole-name {
            z-index: 2;
            background-color: $green-blue;
            color: white;
            width: 130px;
            margin: 5px 10px;
            border-radius: 50px;
            height: 75px;
            padding: 5px;
            font-weight: bold;
            text-align: center;
            display: flex;
            justify-content: center;
            p {
                margin: auto;
                line-height: 1;
                word-break: break;
            }
        }
    }
}

@include lg {
    #poles {
        .container-centered {
            flex-direction: row;
            justify-content: center;
        }
    }
}
