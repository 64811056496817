// ----- Flex -----
.flex-direction-column {
    flex-direction: column;
}
.flex-direction-column-reverse {
    flex-direction: column-reverse;
}

// ----- Default -----
a,
a:hover {
    text-decoration: none;
    color: inherit;
}

ul,
ul li,
li {
    list-style-type: none;
}

img {
    pointer-events: none;
}
.copy-icon {
    cursor: pointer;
}

iframe {
    height: 50vw;
    width: 90vw;
    margin-top: 50px;
    border-radius: 4px;
    box-shadow: 0 0 10px 1px $light-grey;
}


.pagination {
    position: relative;
    margin: 25px auto;
    background-color: $green-blue;
    border-radius: 25px;
    padding: 15px 25px;
    justify-content: center;
    .pagination__link--active {
        a {
            background-color: $orange;
            font-weight: bold;
        }
    }
    .pagination__link {
        font-size: 20px;
    }
    li {
        color: $white;
        a {
            position: relative;
            border-radius: 50%;
            display: inline-block;
            color: $white;
            text-align: center;
            width: 30px;
            height: 30px;
            font-size: 20px;       
         }
    }

  
}


/* ----- Media query ----- */
@include sm {
    .min-sm-flex-direction-column-reverse {
        flex-direction: column-reverse;
    }
    .min-sm-flex-direction-column {
        flex-direction: column;
    }
  
    .pagination {
        .pagination__link {
            font-size: 22px;
        }
        li {
            a {
                font-size: 22px;
            }
        }
    }
}

@include md {
    .min-md-flex-direction-column-reverse {
        flex-direction: column-reverse;
    }
    .min-md-flex-direction-column {
        flex-direction: column;
    }
    iframe {
        height: 450px;
        width: 800px;
    }
}

@include sm-landscape {
}

@include lg {
    .min-lg-flex-direction-column-reverse {
        flex-direction: column-reverse;
    }
    .min-lg-flex-direction-column {
        flex-direction: column;
    }
    .pagination {
        margin: 50px auto;
        .pagination__link {
            font-size: 26px;
        }
        li {
            a {
                width: 35px;
                height: 35px;
                font-size: 26px;
            }
        }
    }
  
}

@include xl {
    .min-xl-flex-direction-column-reverse {
        flex-direction: column-reverse;
    }
    .min-xl-flex-direction-column {
        flex-direction: column;
    }
    .pagination {    
        li {
            a {
                width: 40px;
                height: 40px;
                font-size: 30px;
            }
        }
    }
    
}
