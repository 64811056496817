#header-tablet {
    nav {
        position: relative;
        z-index: 3;
        background: linear-gradient(90deg, $green-light 0%, $green-blue 100%);
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 15px;

        #logo {
            width: 100px;
            height: auto;
        }
        #dropdown-icon {
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            fill: white;
            &:hover {
                cursor: pointer;
            }
        }
    }
    #dropdown-container {
        z-index: 2;
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        height: auto;
        width: 100vw;
        background-color: $green-white;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform: translateY(-350px);
        padding-top: 100px;
        box-shadow: 0 0 10px 1px $light-grey;

        // ----- Follow us -----
        #social-media {
            margin: 15px 0;
            a {
                &:nth-child(1) {
                    margin-right: 25px;

                    img {
                        width: 46px;
                        height: 46px;
                    }
                }
                &:nth-child(2) {
                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }

        .title-section {
            font-weight: bold;
            padding-top: 15px;
            min-height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            &:hover {
                cursor: pointer;
            }
            //Arrow
            img {
                width: 12.621px;
                height: 7.061px;
                margin-top: -17px;
                margin-left: 5px;
            }
        }
        .btn-white {
            margin: 20px 0;
        }
        // ----- Hr div -----
        .dropdown-divider {
            width: 70%;
            border-top: 1px solid #e2e2e2;
        }

        .toggled-section {
            display: none;
            transform: translateY(-25px);
            flex-direction: column;
            padding-bottom: 25px;
            font-weight: 500;
            text-align: left;
            a {
                display: block;
                img {
                    width: 40px;
                    height: auto;
                    margin: auto 15px auto 0;
                }
            }
        }
    }
}
