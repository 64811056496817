#projectPage {
    #projectPresentationPage {
        .project-image {
            margin-top: 25px;
            width: 70vw;
            height: auto;
        }
    }
    .title-project {
        text-align: center;
        font-weight: bold;
        margin-top: 25px;
        margin-bottom: 15px;
    }

    .sector-and-job {
        display: flex;
        flex-direction: space-between;
        justify-content: center;
        text-align: center;
        width: 100%;
        .job,
        .sector {
            display: flex;
            flex-direction: column;
            width: 50%;
        }
        .title {
            margin-bottom: 0;
            color: $green-blue;
            font-weight: bold;
        }
        .type {
            font-weight: bold;
            margin-top: 10px;
            font-size: 20px;
            margin-bottom: 0;
        }
        img {
            width: 80px;
            height: auto;
        }
    }
}

@include sm {
    #projectPage {
        #projectPresentationPage {
            .project-image {
                width: 50vw;
            }
        }
    }
}
@include md {
    #projectPage {
        #projectPresentationPage {
            .project-image {
                width: 40vw;
            }
        }
    }
}

@include lg {
    #projectPage {
        .title-project {
            text-align: right;
        }
    
    }
}

@include xl {
    #projectPage {
        #projectPresentationPage {
            .project-image {
                width: 30vw;
                margin-left: 10vw;
            }
        }
        .title-project {
            font-size: 50px;
        }
        .sector-and-job {
            .type {
                font-size: 30px;
            }
        }
    }
}
