#contactUs {
    padding-left: 5vw;
    padding-right: 5vw;
    margin: 10vh 0;
    h2 {
        margin-top: 5vh;
        @include title-bubble-left($orange);
    }  
}
#frame-contact {
    width: 100%;
    height: 75vh;
    box-shadow: unset !important;
}
.modal-contact {
    .modal-content {
        border: none;
        display: block;
        background-color: transparent !important;
    }
}

/* ----- Media query ----- */
@include sm {
    #contactUs {
        padding-left: 10vw;
        padding-right: 10vw;
    }
}

@include md {
    #contactUs {
        padding-left: 15vw;
        padding-right: 15vw;
    }
}

@include xl {
    #contactUs {
        padding-left: 20vw;
        padding-right: 20vw;
    }
    .modal-contact {
        .modal-content {
            width: 800px !important;
            margin-left: -150px;
        }
    }
    #frame-contact {
        height: 80vh;
    }
}
