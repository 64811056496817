#header-desktop {
    font-weight: bold;

    // ----- Navbar visible -----
    nav {
        position: relative;
        z-index: 3;
        background: linear-gradient(90deg, $green-light 0%, $green-blue 100%);
        height: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 0 15px;
        color: $white;

        #nav-left,
        #nav-right {
            width: 220px;
        }

        // ----- Follow us -----
        #nav-left {
            a {
                &:nth-child(1) {
                    margin-right: 15px;

                    img {
                        width: 46px;
                        height: 46px;
                    }
                }
                &:nth-child(2) {
                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }

        #nav-centered {
            display: flex;
            #logo {
                width: 120px;
                height: 120px;
                background-image: url("../../images/logos/logo-white.png");
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;

                &:hover {
                    background-image: url("../../images/logos/rosace-white.png");
                }
            }
            .item-container {
                margin: auto 0;
                width: 150px;
                a {
                    font-size: 18px;
                }
                .item-toggled {
                    display: flex;
                    justify-content: center;
                    font-size: 18px;
                    cursor: pointer;
                    text-underline-offset: 7px;
                    position: relative;

                    &:hover {
                        text-decoration: underline;
                    }
                    p {
                        margin: 0;
                    }
                    //Arrow
                    img {
                        width: 12.621px;
                        height: 7.061px;
                        margin-top: 9px;
                        margin-left: 5px;
                        filter: invert(100%);
                    }
                }
            }
        }
    }

    // ----- Dropdown hidden -----
    .dropdown-container {
        display: none;
        position: absolute;
        background-color: $blue-white;
        width: auto;
        height: auto;
        margin-top: 62px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 2;
        color: black;
        padding: 25px;
        .list-item-dropdown {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .item-dropdown {
                display: flex;
                flex-direction: column;
                text-align: left;
                img {
                    width: 45px;
                    height: 45px;
                    margin-right: 10px;
                }
                a {
                    display: block;
                    font-size: 18px;
                    font-weight: 400;
                    margin: auto 0;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    // ----- Dropdown by section -----
    #projects-dropdown,
    #resources-dropdown {
        .list-resources {
            display: flex;
            justify-content: space-between;
            .item-resources {
                margin: 0 15px;
                display: flex;
                flex-direction: column;

                .jobAndSector {
                    display: flex;
                    float: left;
                    text-align: left;
                    margin-right: auto;
                    margin-left: 0;
                }

                b {
                    font-size: 20px;
                    margin-bottom: 10px;
                    font-weight: 600;
                }
            }
        }
    }
}
