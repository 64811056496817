#corporateValues {
    text-align: center;
    font-weight: bold;

    .container-components {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .container-centered {
        flex-wrap: wrap;
        justify-content: center;
    }
    // .description {
    //     // text-align: left;
    //     // margin-left: 10vw;
    //     font-size: 24px;
    //     margin-bottom: 25px;
    // }
    #ourValues {
        margin: 50px auto;
        .component-ourValues {
            width: 160px;
            padding: 10px;
            margin: 25px 5px;
            min-height: 80px;
            display: flex;
            justify-content: center;
            font-size: 22px;
            p {
                margin: auto;
            }
        }
    }

    #trust {
        margin: 80px auto 50px auto;

        .component-trust {
            background-color: $blue-dark;
            color: white;
            width: 230px;
            padding: 10px;
            margin: 15px;
            height: 150px;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            text-align: center;

            p {
                margin: auto;
            }
        }
    }
    .blue-component {
        border-top: 10px solid $green-blue;
        border-bottom: 10px solid $green-blue;
    }
    .green-component {
        border-top: 10px solid $green-ted;
        border-bottom: 10px solid $green-ted;
    }
    .dark-green-component {
        border-top: 10px solid $green-dark;
        border-bottom: 10px solid $green-dark;
    }
    .dark-component {
        border-top: 10px solid $dark;
        border-bottom: 10px solid $dark;
    }
}

@include xl {
    #corporateValues {
        #ourValues {
            .component-ourValues {
                margin: 25px 15px;
            }
        }
    }
}
