#oneCoursePage {
    .partners-container {
        margin: 25px 0 !important;

        .partner {
            min-height: 350px;
            min-width: 400px;
            img[alt] {
                text-align: center;
            }
        }
    }
    .xs-logo-presentation {
        height: 60px;
        max-height: 60px;
    }
    .md-logo-presentation {
        max-height: 100px;
        height: 100px;
    }
    .xl-logo-presentation {
        height: 180px;
        max-height: 180px;
    }
    .container-carousel {
        .partner {
            margin: auto;
        }
    }
}

/* ----- Media query ----- */
@include sm {
    #oneCoursePage {
        .xs-logo-presentation {
            height: 80px;
            max-height: 80px;
        }
        .md-logo-presentation {
            max-height: 120px;
            height: 120px;
        }
        .xl-logo-presentation {
            height: 240px;
            max-height: 240px;
        }
    }
}

@include md {
    #oneCoursePage {
        .xs-logo-presentation {
            height: 100px;
            max-height: 100px;
        }
        .md-logo-presentation {
            max-height: 140px;
            height: 140px;
        }
        .xl-logo-presentation {
            height: 280px;
            max-height: 280px;
        }
    }
}

@include xl {
    #oneCoursePage {
        .md-logo-presentation {
            max-height: 160px;
            height: 160px;
        }
        .xl-logo-presentation {
            height: 300px;
            max-height: 300px;
        }
    }
}
