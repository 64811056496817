#whoWeAre {
    #some-numbers {
        .all-numbers {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .number-component {
            margin: 25px 0;
            width: 300px;
            .hr {
                height: 15px;
                width: 100%;
                margin-bottom: 25px;
            }
            .title-key {
                font-size: 30px;
                font-weight: bold;
                // min-height: 70px;
            }
            .number {
                font-weight: bold;
                font-size: 80px;
            }
            .description-key {
                font-size: 16px;
            }
        }
        .blue-component {
            .hr {
                background-color: $blue-dark;
            }
            .title-key,
            .number {
                color: $blue-dark;
            }
        }
        .green-component {
            .hr {
                background-color: $green-ted;
            }
            .title-key,
            .number {
                color: $green-ted;
            }
        }
        .dark-green-component {
            .hr {
                background-color: $green-dark;
            }
            .title-key,
            .number {
                color: $green-dark;
            }
        }
        .dark-component {
            .hr {
                background-color: $dark;
            }
            .title-key,
            .number {
                color: $dark;
            }
        }
    }
}

@include md {
    #whoWeAre {
        #some-numbers {
            .all-numbers {
                flex-direction: row;
                flex-wrap: wrap;
            }
            .number-component {
                margin: 25px;
                margin-bottom: auto;
                    width: 250px;
                    .hr {
                        height: 25px;
                       
                    }
                .title-key {
                    min-height: 90px;
                }
                .number {
                    min-height: 70px;
                }
            }
        }
    }
}
