#whoWeAre {
    h1 {
        width: 90%;
        text-align: left;
        font-weight: bold;
    }
    #rosace-blue {
        position: absolute;
        top: -110px;
        left: 0;
        opacity: 0.3;
        width: 600px;

        img {
            width: 600px;
            height: auto;
        }
    }
    // Block by theme
    .title {
        display: inline-flex;

        .rosace {
            min-width: 75px;
            width: 75px;
            height: auto;
        }
        h2 {
            font-size: 36px;
            margin: auto 0;
        }
    }
    .description {
        padding: 0 5vw;
    }
    #mission {
        text-align: left;
        .title {
            padding: 0 calc(5vw - 15px);
        }
    }
    #history {
        .history-text-side {
            margin-left: auto;
            text-align: right;
            .title {
                padding: 0 5vw;
            }
        }
        #mathias {
            width: 300px;
            height: auto;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            display: block;
            margin: 25px auto 25px 0;
        }
    }
}

@include sm {
    #whoWeAre {
        #rosace-blue {
            right: -100px;
            left: unset;
        }
        .title {
            .rosace {
                min-width: 100px;
                width: 100px;
            }
        }
    }
}

@include md {
    #whoWeAre {
        .description {
            font-size: 20px;
        }
        #history {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 40px;
            .history-text-side {
                margin: auto 0;
            }
            #mathias {
                width: 400px;
            }
        }
    }
}

@include xl {
    #whoWeAre {
        #rosace-blue {
            width: 1000px;
            right: -350px;
            top: -250px;
            img {
                width: 1000px;
            }
        }
    }
}
@include xxl {
    #whoWeAre {
        .description {
            font-size: 22px;
        }
        #mission {
            .description {
                padding-right: 25%;
            }
        }
        #history {
            .history-text-side {
                padding-left: 10%;
            }
            #mathias {
                width: 500px;
                border-top-right-radius: 45%;
                border-bottom-right-radius: 45%;
                margin-left: -70px;
            }
        }
    }
}
