#all-filter {
    margin-bottom: 50px;
    background-color: #7eacb944;
    border-radius: 20px;
    padding-bottom: 25px;
    padding-top: 10px;

    h3 {
        color: $grey;
    }
    .container-centered {
        align-items: unset;
        flex-direction: column;
        .one-side {
            width: 100%;
        }
    }
    .container-label-filtersBy {
        display: flex;
        flex-wrap: wrap;
    }
    .btn-label {
        background-color: $white;
        color: $green-blue;
        border-radius: 35px;
        border: 2px solid white;
        margin: 0 15px 15px 0;
        padding: 10px 30px;
        font-weight: bold;
        font-size: 18px;
        display: inline-block;
        box-shadow: 0 0 5px 1px rgb(225, 225, 225);
        &:hover {
            background-color: rgb(250, 250, 250);
            border: 2px solid $green-blue;
        }
    }
    .btn-label.is-active {
        background-color: $green-blue;
        color: $white;
    }

}

/* ----- Media query ----- */
@include xl {
    #all-filter {
        h3 {
            font-size: 24px;
        }
        .container-centered {
            flex-direction: row;
            padding: 0 5vw;

            .one-side {
                width: 48%;
            }
        }

        .btn-label {
            background-color: $white;
            color: $green-blue;
            border-radius: 35px;
            border: 2px solid white;
            margin: 0 15px 15px 0;
            padding: 10px 30px;
            font-weight: bold;
            font-size: 18px;
            display: inline-block;
            &:hover {
                background-color: rgb(250, 250, 250);
                border: 2px solid $green-blue;
            }
        }
        .btn-label.is-active {
            background-color: $green-blue;
            color: $white;
        }
       
    }
}
