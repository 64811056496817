// ----- Page container -----
#AlizCalendar__Widget__Button {
    z-index: 1000;
}
.page {
    position: relative;
    min-height: calc(100vh - 100px);
    z-index: 1;
    overflow-x: hidden;
}
.component {
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;
    height: auto;
    z-index: 1;
    overflow-x: hidden;
}

// ----- Centered container -----
.container-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: left;
    padding: 0 5vw;
}
// ----- .centered-container.reverse -----
.reverse,
.not-reverse {
    flex-direction: column;
    margin-bottom: 50px;
}

// ----- Centered container divided page in two block -----
.one-side {
    width: 80%;

    img {
        display: block;
        width: 250px;
        height: auto;
        border-radius: 4px;
        margin: 0 auto;
    
    }
    .title {
        margin: 25px 0;
        font-weight: bold;
    }
    .description {
        margin-bottom: 25px;
    }
}

h2 {
    position: relative;
    z-index: 5;
    font-size: 30px;
    margin-bottom: 70px;
    font-weight: bold;
    display: inline-block;
}
h3 {
    position: relative;
    z-index: 5;
    font-size: 18px;
    margin-bottom: 25px;
    font-weight: bold;
    display: inline-block;
}
.subtitle {
    width: 80%;
    margin: auto auto 25px auto;
}
#firstBlock-presentation {
    .one-side {
        .description {
            b {
                color: $green-blue;
            }
        }
        .btn-orange {
            margin-bottom: 60px;
        }
    }
    .illustration-side {
        img {
            display: block;
            width: 90vw;
            height: auto;
            margin-left: -30vw;
        }
    }
}

.bg-lightblue {
    background-color: $blue-white;
}
.bg-lightgreen {
    background-color: $green-white;
}

/* ----- Media query ----- */
@include sm {
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 20px;
    }
    #firstBlock-presentation {
        .illustration-side {
            img {
                width: 450px;
            }
        }
    }
}

@include md {
    .one-side {
        width: 60%;
    }

    h3 {
        font-size: 22px;
    }
    #firstBlock-presentation {
        .illustration-side {
            img {
                width: 450px;
                margin-left: -20vw;
            }
        }
    }
}

@include sm-landscape {
}

@include lg {
    .container-centered {
        justify-content: space-between;
    }

    .not-reverse {
        flex-direction: row;
    }
    .reverse {
        flex-direction: row-reverse;
        text-align: right;
    }
    .one-side {
        width: 48%;
    }
    #firstBlock-presentation {
        .one-side {
            .title {
                font-size: 32px;
            }
            .description {
                font-size: 16px;
            }
        }
        .illustration-side {
            img {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 70vw;
                height: auto;
            }
        }
    }
}

@include xl {
    .page {
        min-height: calc(100vh - 150px);
    }
    .container-centered {
        padding: 0 5vw;
    }
    .one-side {
        .title {
            font-size: 40px;
        }
        .description {
            font-size: 18px;
        }
    }
    #firstBlock-presentation {
        .illustration-side {
            img {
                width: 70vw;
            }
        }
    }

    h1 {
        font-size: 50px;
    }
    h2 {
        font-size: 50px;
    }
    h3 {
        font-size: 20px;
    }
    .subtitle {
        width: 60%;
        margin: auto;
        font-size: 20px;
    }
}

@include xxl {
    #firstBlock-presentation {
        .one-side {
            .title {
                font-size: 40px;
            }
            .description {
                font-size: 18px;
            }
        }
        .illustration-side {
            img {
                width: 60vw;
            }
        }
    }
}
